@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    font-family: 'Poppins', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f7f7f7;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #3e4e5c;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3b3f53;
}

.small-bar::-webkit-scrollbar {
    width: 4px;
}

.small-bar::-webkit-scrollbar-track {
    background: #f3fcff;
}

.small-bar::-webkit-scrollbar-thumb {
    background: #3e4e5c;
}

.small-bar::-webkit-scrollbar-thumb:hover {
    background: #3b3f53;
}
